import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useMemo, useState } from 'react';
import type { PopulatedMenu } from 'root/apiTypes';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import { getMenusToDisplay } from './utils';
import type { MenuOrder } from 'root/components/Menus/Settings/Tabs/MenusTab/MenusSettingsParams';
import { settingsParams } from 'root/components/Menus/settingsParams';
import { Preset } from 'root/utils/presets';
import stylesParams from 'root/components/Menus/stylesParams';
import type { ItemImage } from '@wix/restaurants-populated-item-client/dist/types/types';

export const useMenusLogic = ({ menus, initialActiveTab }: { menus: PopulatedMenu[]; initialActiveTab: number }) => {
  const settings = useSettings();
  const styles = useStyles();
  const menusDisplayOrder = settings.get(settingsParams.menusDisplayOrder!) as MenuOrder[];

  const displayableMenus = useMemo(() => {
    if (!menusDisplayOrder) {
      return menus;
    }
    return getMenusToDisplay({ menusDisplayOrder, menus });
  }, [menus, menusDisplayOrder]);

  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const { showTabs, showMenusSideImage } = useGetDisplayStylesParams();

  const shouldShowTabs = displayableMenus.length > 1 && showTabs;

  const preset = styles.get(stylesParams.preset);
  const shouldShowMenusSideImage = showMenusSideImage && [Preset.SideBySide].includes(preset);
  const menusSideImage = settings.get(settingsParams.menusSideImage) as ItemImage;

  return {
    activeTab,
    displayableMenus,
    shouldShowTabs,
    menusDisplayOrder,
    setActiveTab,
    shouldShowMenusSideImage,
    menusSideImage,
  };
};
